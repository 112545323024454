class FsCalendar {
  onchange: any = function () {};

  private inputElement: HTMLInputElement;

  private calendarElement: HTMLElement;

  private contentElement: HTMLElement;

  private monthElement: HTMLElement;

  private yearElement: HTMLElement;

  private currentMonth: number;

  private currentYear: number;

  private allow: string[] = [];

  private activeDate: any;

  private today: any;

  private monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ];

  constructor(inputElement: HTMLInputElement) {
    /**
     * Elements
     */
    this.inputElement = inputElement;

    this.calendarElement = document.getElementById($(inputElement).data('calendar-id'));
    this.contentElement = this.calendarElement.querySelector('.fs-calendar-content');
    this.monthElement = this.calendarElement.querySelector('.fs-calendar-month');
    this.yearElement = this.calendarElement.querySelector('.fs-calendar-year');

    /**
     * Create Month
     */
    this.currentMonth = parseInt(this.calendarElement.dataset.month);
    this.currentYear = parseInt(this.calendarElement.dataset.year);
    this.today = this.calendarElement.dataset.today;

    if (this.calendarElement.dataset.allow) {
      this.allow = this.calendarElement.dataset.allow.split(',');
    }

    this.createMonth(this.currentMonth, this.currentYear);

    /**
     * Events
     */
    $(this.calendarElement).find('.next').click(() => {
      this.next();
    });

    $(this.calendarElement).find('.prev').click(() => {
      this.prev();
    });

    $(this.contentElement).on('click', 'button', (event: JQueryEventObject) => {
      let button = $(event.target);
      let year = button.data('year');
      let month = button.data('month');
      let day = button.data('day');

      this.activeDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));

      $(this.contentElement).find('.active').removeClass('active');
      button.addClass('active');

      $(this.inputElement).val(button.data('rudate'));
      this.hide();

      this.onchange();
    });

    $(document).mouseup((event: any) => {
      let focus = $(this.inputElement).is(':focus');

      if ($(this.calendarElement).has(event.target).length === 0 && !focus) {
        this.hide();
      }
    });

    $(this.inputElement).focus(() => {
      this.show();
    });

    let date: string, calendarDate: string;

    date = this.inputElement.value;

    if (date) {
      calendarDate = date.substr(6, 4) +
        date.substr(3, 2) +
        date.substr(0, 2);

      this.setDate(calendarDate);
    }
  }

  next() {
    this.currentMonth++;

    if (12 < this.currentMonth) {
      this.currentMonth = 1;
      this.currentYear++;
    }

    this.createMonth(this.currentMonth, this.currentYear);
  }

  prev() {
    this.currentMonth--;

    if (1 > this.currentMonth) {
      this.currentMonth = 12;
      this.currentYear--;
    }

    this.createMonth(this.currentMonth, this.currentYear);
  }

  setDate(date: string) {
    var day, month, year;

    switch (date) {
      case 'today':
        let today = new Date();
        day = today.getUTCDate();
        month = today.getUTCMonth() + 1;
        year = today.getUTCFullYear();
        break;
      case 'tomorrow':
        let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

        day = tomorrow.getUTCDate();
        month = tomorrow.getUTCMonth() + 1;
        year = tomorrow.getUTCFullYear();
        break;
      default:
        day = parseInt(date.substr(6, 2));
        month = parseInt(date.substr(4, 2));
        year = parseInt(date.substr(0, 4));
    }

    this.createMonth(month, year);
    this.currentMonth = month;
    this.currentYear = year;

    this.activeDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));

    $(this.contentElement).find('[data-day=' + day + ']').addClass('active');
  }

  /**
   * Делает дату недоступной
   *
   * @param {string} date Дата в формате YYYYmmdd или dd.mm.YYYY
   */
  setInactive(date: string) {
    date = date.split('.').reverse().join('');

    let index = this.allow.indexOf(date);

    if (-1 !== index) {
      delete this.allow[index];
      $(this.contentElement).find('button[data-date=' + date + ']').prop('disabled', 'disabled');
    }
  }

  getDate(): Date {
    return this.activeDate ? this.activeDate : new Date();
  }

  getStringDate(offset: number): string {
    let date = new Date(new Date().getTime() + (24 * 60 * 60 * 1000 * offset));
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getUTCFullYear();

    return (9 < day ? '' : '0') + day + '.' + (9 < month ? '' : '0') + month + '.' + year;
  }

  reset() {
    let date = new Date();
    $(this.contentElement).find('.active').removeClass('active');
    this.createMonth(date.getUTCMonth() + 1, date.getUTCFullYear());
    this.inputElement.value = '';
  }

  show() {
    if ('block' != this.calendarElement.style.display) {
      let date: string, calendarDate: string;

      date = this.inputElement.value;

      if (date) {
        calendarDate = date.substr(6, 4) +
          date.substr(3, 2) +
          date.substr(0, 2);

        this.setDate(calendarDate);
      }

      this.calendarElement.style.display = 'block';
    }
  }

  hide() {
    this.calendarElement.style.display = 'none';
  }

  isSunday(date: string): boolean {
    let weekday: number;

    date = date.split('.').reverse().join('-');
    weekday = (new Date(Date.parse(date))).getUTCDay();

    if (0 === weekday) {
      return true;
    }

    return false;
  }

  isActiveDay(date: string): boolean {
    date = date.split('.').reverse().join('');
    return -1 !== this.allow.indexOf(date) ? true : false;
  }

  private getLastDayOfMonth(year: number, month: number): number {
    var currentMounth: any = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0));
    var nextMounth: any = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));
    var result = (nextMounth - currentMounth) / 86400000;

    return result;
  }

  private createMonth(month: number, year: number) {
    var firstDay = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0));
    var day = 0;
    var html = '';
    var daysInMonth, dayOfWeek, weeksInMonth;

    this.monthElement.innerHTML = this.monthNames[month - 1];
    this.yearElement.innerHTML = year.toString();

    daysInMonth = this.getLastDayOfMonth(year, month);
    dayOfWeek = firstDay.getUTCDay();

    if (0 === dayOfWeek) { // Sunday
      dayOfWeek = 7;
    }

    weeksInMonth = Math.ceil((dayOfWeek - 1 + daysInMonth) / 7);

    for (var i = 0; weeksInMonth > i; i++) {
      var div = document.createElement('div');
      for (var j = 0; 7 > j; j++) {
        var visible = false;

        if (0 === i && j < dayOfWeek - 1) {
          day = 0;
        } else {
          day++;
        }

        if (day <= daysInMonth && 0 < day) {
          visible = true;
        }

        if (visible) {
          var button = document.createElement('button');

          button.type = 'button';

          if (4 < j) {
            button.classList.add('week-end');
          }

          button.dataset.rudate = (9 < day ? '' : '0') + day + '.' + (9 < month ? '' : '0') + month + '.' + year;
          button.dataset.date = year + (9 < month ? '' : '0') + month + (9 < day ? '' : '0') + day;
          button.dataset.year = year.toString();
          button.dataset.month = month.toString();
          button.dataset.day = day.toString();
          button.innerHTML = day.toString();
          button.disabled = -1 === this.allow.indexOf(button.dataset.date) ? true : false;

          if (this.today === button.dataset.date) {
            button.classList.add('highlight');
          }

          if (this.activeDate === button.dataset.date) {
            button.classList.add('active');
          }

          if (button.disabled) {
            button.title = 'Доставка на эту дату невозможна';
          } else {
            button.title = 'Доставка на эту дату возможна';
          }

          div.appendChild(button);
        } else {
          var span = document.createElement('span');
          span.className = 'empty-block';
          div.appendChild(span);
        }
      }

      html += div.outerHTML;
    }

    this.contentElement.innerHTML = html;
  }
};
